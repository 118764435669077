<template>

  <div class="container-fluid results-view-v2" v-if="dashboard && session && product">

    <div v-if="!completed && !initial">
      <ProgressIndicator :dashboard="dashboard" :session="session" @resume-session="resumeSession" @on-progress-complete="onProgressComplete" />
    </div>    

    <div v-else>

      <b-overlay :show="busy">        

        <div class="row">

          <ConfirmDialog :title="confirm.title" :click="onConfirmComplete" :open="confirm.open" />

          <div class="sidebar" :class="colsFor('sidebar')">
            <SideBar :session="session" @on-platform-selected="onPlatformSelected" @on-toggle-sidebar="toggleSidebar" :expanded="expanded" :selected="selectedPlatform"/>
          </div>

          <div class="content" :class="colsFor('content')">

            <PostsCarousel 
                  :content="content" 
                  :session="session" 
                  :dashboard="dashboard" 
                  :selectedPost="selectedPost" 
                  :policy="policy" 
                  @on-post-selected="onPostSelected" 
                  @on-action="onAction"/>

          </div>

        </div>

      </b-overlay>

    </div>

  </div>

</template>

<style>



</style>

<script>

import ConfirmDialog from '@/components/ConfirmDialog'
import ProgressIndicator from './ProgressIndicator'

import PostsCarousel from './PostsCarousel'
import SideBar from './SideBar'

import { actions, getters } from '@/services/store'
import BulkActions from '@/services/bulk-actions'
import Selections from '@/services/selections'
import router from '@/services/router'
import User from '@/services/user'

import Vue from 'vue'

// use an irregular poll structure
const pollInterval = () => {
  const min = 1500, max = 5000
  return Math.trunc(Math.random() * (max - min) + min)
}

export default {

  name: 'ResultsView',

  data() {
    return {
      post:false,
      content:[],
      variants:[],
      busy:false,
      ready:false,
      policy:false,
      initial:true,
      partner:false,
      session:false,
      completed:false,
      dashboard:false,
      onboarded:false, 
      selectedChannel:'',    
      sessionId:undefined,  
      sideBarExpanded: true,
      selectedPost: undefined,      
      statusMessage:undefined,   
      selectedPlatform:undefined,
      mobile:getters.isMobile(),
      originalsRemaining: {},
      confirm: {
        title:'',
        action: '',
        open: false
      }
    }
  },



  /**
   * Load the session and populate available channels etc
   */
  async created() {

    try {

      this.session = await actions.findSession(this.$route.params.sessionId)
      this.onboarded = User.getOnboardingKey('generator')
      this.policy = await actions.fetchPolicy()
      this.dashboard = getters.dashboard()
      this.product = getters.product()
      this.partner = getters.partner()
      
      // update the current user from server in order 
      // to retrieve any changes in onboarding status 
      this.user = actions.fetchUser()

      this.poll();

    } catch ( err ) {
      console.error('TODO- PROPER ERROR MESSAGE HERE')
      console.error(err)
    }

  },

  computed: {
    expanded() {
      return this.$mq === 'xl'? this.sideBarExpanded : false
    }
  },

  methods: {

    onSendTo() {
      console.log('onSendTo',arguments)
    },

    onScheduled() {
      console.log('onScheduled',arguments)
    },

    toggleSidebar() {
      this.sideBarExpanded = !this.sideBarExpanded
    },

    colsFor(region) {
      let cols
      if ( region === 'sidebar' ) {
        cols = this.expanded? '2' : '1'
      } else {
        cols= this.expanded? '10' : '10 offset-2'
      }
      return `col-${cols}`
    },

    async onConfirmComplete(confirm) {
      this.confirm.open = false       
      if ( confirm ) {
        await this.onAction({
          action:this.confirm.action
        },true)
      }
    },

    async onAction(evt,confirm) {

      switch(evt.action) {

        case 'schedule': {
          this.post = evt.post
          break;
        }

        // single deletion  
        case 'delete': {
          await BulkActions.remove([evt.post],this.session,this.content)
          Vue.nextTick(()=>{
            this.$toasted.success(`Post removed`)          
            this.selectedPost = undefined
            Selections.exclude(evt.post)
          })
          break;
        }

        // selections deletion
        case 'delete-selections': {

          let posts = Selections.items()

          if ( posts.length > 1 && !confirm) {
            this.confirm.title = `Remove ${posts.length} posts?`
            this.confirm.action = 'delete-selections'
            this.confirm.open = true
          } else {
            this.busy = true
            let count = await BulkActions.remove(posts,this.session,this.content)

            // clear editor selection 
            if ( this.selectedPost && posts.find((p)=>{return p._id === this.selectedPost._id }) ) {
              this.selectedPost = undefined
            }
            this.busy = false

            // clear selections
            Selections.clear()
            this.$toasted.success(`Removed ${count} posts`)       
            
            this.postAction('delete-selections')
            
          }
          break;
        }
      }

    },

    postAction(action) {
      
      // back to sources if no posts remaining
      if ( !this.session.postsRemaining.all ) {
        this.$router.push(`/${this.dashboard._id}/sources`)
      } else if ( !this.session.postsRemaining[this.platform] ){
        let platform = Object.keys(this.session.postsRemaining).find((type)=>{
          return type !== 'all' && this.session.postsRemaining[type]
        })
        if ( platform ) {
          Vue.nextTick(()=>{
            this.onPlatformSelected(platform)
          },50)
        }
      }
    },

    async onPlatformSelected(platform) {
      try {
        Selections.clear() // reset current selections
        this.busy=true     
        this.content.length = 0
        this.selectedPlatform = platform;           
        const resp = await actions.fetchSessionContent(this.session,[platform],'*')
        this.ready = true
        this.content = resp.results
        this.variants = resp.variants  
      } catch ( err) {
        console.error('onPlatformSelected caught',err)
      } finally {
        this.busy = false
      }
    },

    onPostSelected(post) {
      this.selectedPost = post
    },

    onOriginalsRemaining(ev) {
      this.originalsRemaining = ev.remaining
      this.selectedChannel = ev.channel
    },

    onToggleAIOriginals(toggle) {
      this.$refs.postsCarousel.onToggleOriginals(toggle)
    },

    onStatusMessage(msg) {
      //console.log('ResultsView.onStatusMessage',msg)
      this.statusMessage = msg
      if ( this.timer ) clearTimeout(this.timer)
      this.timer = setTimeout(()=>{
        this.statusMessage = undefined
      },3000)
    },

    onProgressComplete() {
      this.completed = true
    },

    async resumeSession() {
      const dashboard = getters.dashboard()
      await actions.resumeSession( dashboard._id, this.session._id )
      this.session.status.bucket = 'generating'
      this.poll()
    },

    /**
    * await session completion
    **/
    async poll() {

      const sessionId = this.sessionId || router.currentRoute.params.sessionId
      if ( !sessionId ) return; // possibly navigated

      if ( this.selectedPlatform ) {

        this.idx = -1
        this.content.length = 0
        const resp = await actions.fetchSessionContent(this.session,[this.selectedPlatform],'*')
        // console.log('resultsV2 polled',resp)        
        this.postsRemaining = resp.postsRemaining
        this.variants = resp.variants        
        this.content = resp.results        
      }

      // fetch the session
      Vue.nextTick(async ()=>{

        this.session = await actions.findSession(sessionId)

        this.user = await actions.fetchUser()
        this.onboarded = User.getOnboardingKey('generator')
        if ( !this.session ) return this.$router.push(`/${router.currentRoute.params.dashboardId}/sources`);

        // on initial load set completed flag
        if ( this.initial ) {
          this.initial = false
          this.completed = this.session.status.bucket === 'complete' || (this.session.status.bucket === 'generating' && this.session.postsCreated.all)
        }

        if ( this.session.status.bucket === 'open' ) {
          return this.$router.push(`/${this.session.dashboard}/generate/${this.session._id}`)
        } else if ( this.session.status.bucket != 'complete' && this.session.status.bucket != 'failed' ) {
          setTimeout( this.poll, pollInterval() )
        }
      })

    }

  },

  components:{
    ProgressIndicator,
    ConfirmDialog,
    PostsCarousel,
    SideBar
  }

}
</script>


