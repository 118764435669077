<template>

  <div class="row nomargin content-preview-v3" v-if="!video && post && post.preview_cache && post.preview_cache.image_url">

    <div class="col-md-3 preview-media">
      <img width="100%" :src="image" />
    </div>

    <div class="col-md-9 preview-text">
      <p class="preview-title">{{post.preview_cache.title}}</p>
      <p class="preview-description">{{post.preview_cache.description}}</p>
    </div>

  </div>

</template>

<script>

export default {

  name: 'Preview',

  data() {
    return {
      image:false,
      video:false,
    }
  },

  props: {
    post: {
      type: Object,
      required: true
    }
  },

  created() {
    const src = this.post.preview_cache? this.post.preview_cache.image_url : undefined
    if ( src && src !== 'null' ) {
      this.image = src
    } else {
      this.image = require('@/assets/sourcetypes/link.png')
    }
    this.video = this.post.attachments.find((a)=>{
      return a.mimetype.startsWith('video')
    })
  }

}

</script>

<style lang="scss" >

.content-preview-v3 {

  max-height: 200px;

  background-color: #F4F4F4;
  padding: 20px 14px 20px 14px;
  margin: 0px 0px 10px 0px;

 .preview-media img {
    border-radius: 5px;
    padding: 0px;
    display: inline;
    margin-right: 5px;
  }

 .preview-text {

    padding: 0px 14px 0px 14px;
    color: #9D9D9D;

    .preview-title {
      max-height: 20px;
      font-size: 13px;
      font-weight: bold;
      color: #2D2D2D;
      margin: 0px!important;
      padding: 0px;
    }

    .preview-description {
      font-size: 13px;
      font-weight: normal;
      color: #9D9D9D;
      max-height: 100px;
      padding: 0px;
    }

  }
}

</style>
