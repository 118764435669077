<template>

  <footer class="footer" :class="{active:trial.remaining || trial.expired}" v-if="!hideExpiryBanner">
    <div class="container">
      <p v-if="trial.expired">Your free trial has expired! {{ trial.expired }} {{ trial.remaining }}
        <router-link v-if="showSubscribeLink  && !subscription.subscriptionId" :to="{ name: 'Subscription', dashboardId }"> Subscribe Now</router-link></p>
      <p v-else-if="trial.remaining">Your free trial expires in {{ trial.remaining }} days!
         <router-link v-if="showSubscribeLink  && !subscription.subscriptionId" :to="{ name: 'Subscription', dashboardId }"> Subscribe Now</router-link></p>      
    </div>
  </footer>
</template>

<script>

import { events, getters } from '@/services/store'
import moment from 'moment'

export default {
  data() {
    return {
      subscription: undefined,
      dashboardId: undefined,
      hideExpiryBanner:false,
      showSubscribeLink: true,      
      trial: {
        expired: false,
        remaining: undefined
      },
      user: undefined
    }
  },
  watch:{
    $route (to, from){
      this.showSubscribeLink = !to.meta.hideSignupLink
      this.hideExpiryBanner = to.meta.hideExpiryBanner
    }
  }, 
  methods: {
    resolveTrial() {
      this.user = getters.user()

      if ( this.user && this.user.onboarding && this.user.onboarding.generator ) {
        this.subscription = getters.subscription()      
        // console.log('defaultFooter resolveTrial',JSON.stringify(this.subscription,0,1))
        if ( this.subscription && this.subscription.version === 'V2' && this.subscription.trialing) {
          let expiry = moment(this.subscription.trialEnd)
          let days = expiry.diff(moment(),'days')
          if ( days < 1 ) {
            this.trial.expired = true
          } else {
            this.trial.expired = false
            this.trial.remaining = days
          }
        } 
      } else {
        // console.log('not showing banner for', JSON.stringify(this.user.onboarding,0,1))
        this.trial.expired = false
        this.trial.remaining = undefined
      }
    }
  },
  created() {
    this.dashboardId = this.$route.params.dashboardId        

    // handle refresh scenario 
    this.resolveTrial()        
    
    // handle login or logout scenario
    events.$on('subscription-resolved',()=>{
      this.resolveTrial()
    })

    // handle login or logout scenario
    events.$on('user-resolved',()=>{
      this.resolveTrial()
    })    
 
  }
}
</script>

<style>

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 0px; /* Footer height */
  line-height: 70px; /* Vertically center the text */
  background-color: white; 
  text-align: center;

  a {
    color:#80ff33;
    cursor: hand;
  }


}

.footer.active {
  font-family: Noto Sans;
  font-weight: bold;
  height:75px;
  color: white;
  background-color: #008CFF;
}

</style>