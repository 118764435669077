<template>

  <div class="default-nav">

    <!--
    <div class="twitter-paused" v-if="twitterAlert">
      Twitter API access is currently disrupted. Please re-auth your account, even if you have recently, because they are having issues with their developer platform.
    </div>    
  -->
  
    <!--
    <b-alert show variant="danger" v-if="!ready">
      <h4>Initialized {{store.initialized}}, authenticated {{authenticated() }}</h4>
    </b-alert>
    -->

    <b-alert show variant="danger" v-if="ready && showMobileAlert">
      <h4>This experience is intended for desktop use! <b-link href="#" @click="sendDesktopLink($event)"> Send yourself a link </b-link> to try this on your computer</h4>
    </b-alert>

    <b-navbar class="fixed-top" toggleable="lg" type="light" v-if="ready && dashboardId">

      <div class="container nopad">

        <b-navbar-brand href="#" :to="{ name: 'Sources', dashboardId: dashboardId }">
          <a class="navbar-brand"><img width="100" src="../../assets/lately_logo_nav.jpg" /></a>
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav v-if="dashboardId">

          <!-- left aligned options -->
          <b-navbar-nav v-if="onboarded() && registered() && getOnboardingKey('generator')">
            <b-nav-item :to="{ name: 'Start', dashboardId: dashboardId }">Post Creator</b-nav-item>            
            <b-nav-item :to="{ name: 'Sources', dashboardId: dashboardId }">History </b-nav-item>
            <b-nav-item v-if="!isIframe('hootsuite') && includes('connect-third-party,connect-lately') && dashboard && dashboard.channels.length" :to="{ name: 'Drafts', dashboardId: dashboardId }">{{partner==='lately'? 'Queue':'Drafts'}}</b-nav-item>

            <b-nav-item v-if="onboarded() && includes('connect-third-party,connect-lately') && dashboard && dashboard.channels.length" :to="{ name: 'Calendar', dashboardId: dashboardId }">
              <span v-b-tooltip.hover :title="status.message">{{partner==='lately'? 'Calendar':'Sent'}}
                <b-badge v-if="status.counts.UF" 
                         class="alert-badge" variant="danger" 
                         v-b-tooltip.hover :title="status.counts.UF + ' Posts Failed to Publish'"                         
                         :to="{ name: 'Calendar', dashboardId: dashboardId, query: {view:'list',status:'F',source:'posts-failed-indicator'} }">{{status.counts.UF}}</b-badge>
              </span>
            </b-nav-item>
            
            <b-nav-item v-if="partner==='lately' && dashboard && dashboard.channels.length" :to="{ name: 'Analytics', dashboardId: dashboardId }">Analytics </b-nav-item>               
   
            <!-- DUPLICATED FROM RIGHT MENU BELOW -->
            <b-nav-item v-if="isMobile && admin() && onboarded()" :to="{ name: 'Admin', dashboardId: dashboardId }">Admin</b-nav-item>
            <b-nav-item v-if="isMobile && onboarded()" :to="{ name: 'Settings', dashboardId: dashboardId, query: {tab:'account'} }">Settings</b-nav-item>

            <b-nav-item v-if="isMobile && onboarded() && isOwner && includes('invite-team-members')" :to="{ name: 'Team', dashboardId: dashboardId }">Manage Team</b-nav-item>
            <b-nav-item v-if="isMobile && onboarded() && includes('connect-third-party,connect-lately')" :to="{ name: 'Publisher', dashboardId: dashboardId }">Publisher</b-nav-item>
            <b-nav-item v-if="isMobile && isOwner && subscribed" @click="portal()">Subscription</b-nav-item>
            <b-nav-item v-if="isMobile && onboarded()" target="_" href="https://www.lately.ai/resources/lately-video-tutorials">Help Center</b-nav-item>
            <b-nav-item v-if="isMobile && onboarded()" target="_" href="mailto:customerlove@lately.ai">Support</b-nav-item>
            <b-nav-item v-if="isMobile" @click="logout()">Logout</b-nav-item>

          </b-navbar-nav>

          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">

            <!-- logout menu for unregistered -->
            <span class="nav float-right" v-if="!registered() && !isMobile">
              <b-nav-item-dropdown right toggle-class="text-decoration-none" class="settings-dropdown" no-caret id="settings-dropdown">
                <template #button-content>
                  <span class="user-name">My Account</span>
                </template>
                <b-dropdown-item @click="logout()">Logout</b-dropdown-item>
              </b-nav-item-dropdown>
            </span>

            <span class="nav float-right" v-if="registered() && !isMobile">

              <!-- NOT SHOWN FOR MOBILE - DUPLICATE NAV ITEMS TO MENU ABOVE FOR MOBILE -->

              <b-nav-item-dropdown right toggle-class="text-decoration-none" class="settings-dropdown" no-caret id="settings-dropdown">
                <template #button-content>
                  <span class="user-name">My Account</span>
                </template>

                <!-- admin if is admin -->
                <b-dropdown-item v-if="admin() && onboarded()" :to="{ name: 'Admin', dashboardId: dashboardId }">Admin</b-dropdown-item>
                <b-dropdown-divider v-if="admin() && onboarded()"></b-dropdown-divider>

                <!-- settings if onboarded -->
                <b-dropdown-item v-if="onboarded()" :to="{ name: 'Settings', dashboardId: dashboardId, query: {tab:'account'} }">Settings</b-dropdown-item>
                <b-dropdown-divider v-if="onboarded()"></b-dropdown-divider>

                <b-dropdown-item v-if="onboarded() && isOwner && includes('invite-team-members')" :to="{ name: 'Team', dashboardId: dashboardId }">Manage Team</b-dropdown-item>

                <!-- history when onboarded and has connect-third-party -->
                <b-dropdown-item v-if="onboarded() && includes('connect-third-party,connect-lately')" :to="{ name: 'Publisher', dashboardId: dashboardId }">Publisher</b-dropdown-item>
                <b-dropdown-item v-if="isOwner && subscribed" @click="portal()">Subscription</b-dropdown-item>
                <b-dropdown-divider v-if="onboarded() && isOwner"></b-dropdown-divider>

                <b-dropdown-item v-if="onboarded()" target="_" href="https://www.lately.ai/resources/lately-video-tutorials">Help Center</b-dropdown-item>
                <b-dropdown-item v-if="onboarded()" target="_" href="mailto:customerlove@lately.ai">Support</b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item @click="logout()">Logout</b-dropdown-item>
              </b-nav-item-dropdown>
            </span>

          </b-navbar-nav>

        </b-collapse>

      </div>

    </b-navbar>

  </div>

</template>

<script>

  'use strict'

  import { authenticated, onboarded, admin, actions, store, events, getters } from '@/services/store'
  import { includes } from '@/services/product'

  import User from '@/services/user'

  export default {

    data() {
      return {
        twitterAlert:false,
        status: {
          message: '',
          counts: store.status,
        },
        registered: getters.registered,
        onboarded: onboarded,
        subscribed: false,
        authenticated: authenticated,
        isIframe: getters.isIframe,
        getOnboardingKey: User.getOnboardingKey,
        partner: getters.partner(),
        showMobileAlert:false,
        navbarExpanded: false,
        onboarded: onboarded,
        dashboard: false,
        onInterval: false,
        dashboardId: false,
        includes:includes,
        actions:actions,
        isMobile:false,
        isOwner:false,
        timer:false,
        admin:admin,
        store:store
      }
    },

    /**
    due to async nature of some content updates (in particular publishing videos)
    a mix of polling and event driven updates are used to track content status
    **/
    created() {

      this.onInterval = setInterval( this.fetchContentStatus, 30000 )

      this.dashboard = getters.dashboard()
      this.isOwner = getters.isOwner()
      events.$on('post-removed',()=>{
        this.fetchContentStatus()
      })
      events.$on('post-updated',()=>{
        this.fetchContentStatus()
      })
      events.$on('posts-failed-reviewed',()=>{
        if ( this.status.counts.UF ) {
          this.fetchContentStatus()
        }
      })      
      events.$on('subscription-resolved',()=>{
        this.setSubscribed()
      })      
      events.$on('dashboard-resolved',()=>{
        this.setDashboard()
      })          
      events.$on('user-resolved',()=>{
        this.setUser()
      })             
      this.poll()
    },

    destroyed() {
      clearTimeout(this.onInterval)
      events.$off('post-removed')
      events.$off('post-updated')
    },

    computed: {
      ready() {
        return this.dashboardId && authenticated()
      }
    },

    methods: {

      setUser() {
        this.user = getters.user()     
        this.isOwner = getters.isOwner()
      },

      setDashboard() {
        this.dashboard = getters.dashboard()
        this.dashboardId = this.dashboard? this.dashboard._id : ''
      },

      setSubscribed() {
        let subscription = getters.subscription()
        this.subscribed = (subscription && subscription.subscriptionId)        
      },

      async fetchContentStatus() {
          const counts = await actions.fetchContentStatus(this.dashboardId)
          this.status.counts = counts || {}
      },

      onStatusAction(ev) {
        ev.stopPropagation()
        ev.preventDefault()
        if ( this.status.action !== this.$router.currentRoute.path ) {
          this.$router.push(this.status.action)
          }
      },

      // wait for initialization
      poll() {
        if ( !this.dashboardId && getters.dashboard() ) {
          this.dashboardId = getters.dashboard()._id
          this.showMobileAlert = (store.isMobile && !includes('connect-third-party'))
          this.isMobile = getters.isMobile()
          this.isOwner = getters.isOwner()
          this.user = getters.user()
          this.fetchContentStatus()
          this.setSubscribed()
        } else {
          setTimeout( this.poll, 50 )
        }
      },

      async sendDesktopLink(ev) {
        ev.stopPropagation()
        ev.preventDefault()
        try {
          const dashboard = getters.dashboard()
          await actions.sendDesktopLink(dashboard._id)
          this.$toasted.success(`Link sent! Check your email from your desktop`)
        } catch (err) {
          console.error('catch handler',err)
        }
      },

      async portal() {
        const mode = getters.isIframe('hootsuite')
        const p = await actions.fetchStripePortalUrl(mode)
        if ( getters.isIframe('hootsuite') ) {
          window.open(p.data.url,'_blank')
        } else document.location.href = p.data.url
      },

      toggleNavbar() {
        this.navbarExpanded = !this.navbarExpanded
      },

      collapseNavbar() {
        this.navbarExpanded = false
      },

      select(dashboard) {
        actions.selectDashboard(dashboard);
      },

      async generate(source) {
        const resp = await actions.createSession(source)
        this.$router.push(`/${store.dashboard._id}/generate/${resp.session._id}/${resp.count}`)
      },

      logout() {
        this.$router.push('/app/login')        
        actions.logout()
      }

    }
  }
</script>

<style lang="scss">

.default-nav {
  z-index: 9999;
}

.settings-dropdown ul {
  margin-top: 30px;
  background-color: white;
  border: 1px solid lightgrey;
  padding: 5px;
  min-width: 200px;
  border-radius:10px;
}

.settings-dropdown button {
  background-color: white!important;
  border-width:0px;
}

.navbar-brand {
  padding-top: 0px;
  padding-left: 0px!important;
}

#navbarDefault {
  justify-content: space-between;
}

.navbar {
  color:#333!important;
  font-size:  14px;
  font-weight: 600;
}

.navbar .btn {
  color:#333!important;
  font-size:  14px;
  font-weight: 600;
}

.nav-item a {
  color:#333;
  padding: 0.375rem 0.75rem;
  display: inline-block;
  font-weight: 600;
}

.nav-item a:hover {
  cursor: pointer;
}

.navbar-nav .user-name {
  font-weight: 600!important;
  padding:18px 5px;
  color:#333!important;
}

.dashboard-picker button {
  color:#333!important;
  padding-right:20px;
  padding-top:5px;
}

.dashboard-picker-option {
  color:#333;
}

.dashboard-picker-option {
  font-size: 14px;
  font-weight: 600;
}

.alert-container {
  padding-right: 20px;
}

.alert-badge {
  position: absolute;
  top: 10px;
  border-radius: 10px;
  padding: 4px!important;
}
.twitter-paused {
  width:100%;
  position:absolute;
  top:45px;
  z-index:1040;
  background-color: #F39C12;
  text-align: center;
}

</style>
