<template>

<b-overlay :show="busy">

  <div class="success-indicator-results-v2" v-bind:class="offset? 'offset':''" >

    <div class="text-center">
      <p class="title text-center">Refine your voice model by making edits to your posts</p>
      <img src="@/assets/onboarding/v2/heart.png" width="90%" />
    </div>

    <div class="indicator-checklist">

      <transition>
        <div>
          <p  class="list-unstyled indicator-reason text-left" v-for="(reason,idx) in unsatisfied" :key="idx" >
              <span class="indicator-sentiment" :class="mapCls(reason.cls)"> <i class="fa-lg" :class="reason.icon"></i></span>
              <span class="indicator-text" v-b-tooltip.hover :title="reason.tooltip">{{reason.msg}}</span>
          </p>
        </div>
      </transition>

      <transition>
        <div v-if="showDetail" class="text-left">
          <p  class="list-unstyled indicator-reason" v-for="(reason,idx) in satisfied" :key="idx" >
            <span class="indicator-sentiment" :class="mapCls(reason.cls)"> <i class="fa-lg" :class="reason.icon"></i></span>
            <span class="indicator-text" v-b-tooltip.hover :title="reason.tooltip">{{reason.msg}}</span>
          </p>
        </div>
      </transition>

      <transition>
        <WritingTips class="writing-tips" :post="post" :channels="channels" v-if="ready" />
      </transition>

    </div>

  </div>

</b-overlay>  

</template>

<style lang="scss" >

.success-indicator-results-v2 {
  padding: 10px;
  font-family: Noto Sans;
  background-color: white;
  border: 2px solid #20c76e;
  border-radius: 10px;
  .title {
    margin: 18px 18px 9px 18px;
    font-size: 13px;
    font-weight: bold;
  }

  .indicator-gauge {
    padding: 0px 28px 0px 28px;

  }

  .indicator-checklist {
    border-radius: 0px 0px 10px 10px;
    background-color: white;
    padding: 0px 5px 18px 5px;
  }

  .section-divider {
    border: 2px solid lightgrey;
    margin: 18px 0px 18px 0px;
  }

  .indicator-reason {
    background-color: #E8F8F2;
    font-size: 14px;
    margin-top: 5px;
    vertical-align:middle;
    width:100%!important;
    padding: 10px;
    border-radius: 5px;
  }

  .indicator-text {
    font-size: 14px;
    font-weight: normal;
    padding: 5px 5px 5px 10px;
  }

  .indicator-sentiment {
    color: white;
    float: none;
    padding-right: 3px;
    width: 100%;
  }

  .indicator-sentiment.active {
    color: #20C763;
  }

  .results-post-success {
    font-size: 0.9em;
  }

  .results-post-success small {
    font-size: 0.9em;
  }

  .success-checklist-btn {
    margin-top: 28px;
    margin-right: 5px;
    border: 1px solid lightgrey;
    border-radius: 12px;
  }

  button.success-checklist-btn.active {
    color: #5DADE2!important;
    background-color: #20C763!important;
  }

  button.success-checklist-btn.inactive {
    color: black!important;
    background-color: lightgrey;
  }

  .writing-tips {
    margin: 0px;
    border: 0px;
  }

  .writing-tips-text {
    padding: 0px;
  }

  .variant {
    border: 0px;
    font-size:44px;
    position: relative;
    top:45px;
    left:5px;
  }

  .mini-gauge .inner-text {
      width: 95%;
      height: 95%;
  }

  .mini-gauge .inner-text span {
    margin-top:10px;
    max-width: 100px;
    text-align: center;
  }

  .transition {
    position:absolute;
    text-align: center;
    padding: 0px 12px 0px 12px;
    margin: 0px;
    width: 100%;
    top: 153px;
    left: 0px;
  }

  .indicator-summary {
    box-shadow: 2px 2px 2px lightgrey;
    background-color: white;
    border: 1px solid lightgrey;
    border-radius: 10px;
    font: 12px;
    padding: 5px;
    width: 100%;
  }

}

.success-info {
  background-color:white;
  p.title {
    margin-bottom: 8px;
  }
}

.success-indicator-results-v2.offset {
  top: -55px;
}

</style>


<script>

'use strict'

import { getters, actions } from '@/services/store'
import WritingTips from './WritingTips'
import crypto from 'crypto';
import Vue from 'vue'

export default {

  name: 'Checklist',

  data() {
    return {
      score:0,
      variant:'',
      textual:'',
      reasons:[],
      busy:true,
      ready:false,
      timer:false,
      parsed:[],
      channels:[],
      edited:false,
      showDetail:true,
      originalContent:'',
      emotive:'fal fa-circle',
      hoverBodyText: `Lately’s AI gets doubly smart, doubly faster, the more you train your model!
      Follow the prompts below to increase your training score and optimize each post's ROI.`
    }
  },

  props: {
    post: {
      type: Object,
      required: true
    },
    offset: {
      type: Number,
      default: 0
    }
  },

  created() {
    this.dashboard = getters.dashboard()
    let type = this.post.contentType === 'temporary'? this.post.channel : this.post.contentType
    this.channels = this.dashboard.channels.filter((ch)=>{return ch.type === type && ch.content}).map((ch)=>{return ch.content.id})
    Vue.nextTick(async ()=>{
      await this.assess()
    })
  },

  computed: {

    satisfied() {
      return this.reasons.filter((reason)=>{
        return reason.dir > 0 && !reason.hide
      })
    },

    unsatisfied() {
      return this.reasons.filter((reason)=>{
        return reason.dir <=0 && !reason.hide
      })
    }

  },

  components: {
    WritingTips
  },

  watch: {

    // reassess on post change
    post () {
      this.assess()
    },

    // wait for completion of content edit then assess
    'post.content' () {
      this.assess()
    },

    // reassess on attachments change
    'post.attachments': {

      handler() {
        this.assess()
      },
      deep: true

    }
  },

  methods: {

    mapCls(cls) {
      return cls==='text-success'? 'active':''
    },

    async assessImpl() {

      this.busy = true

      // TODO - THIS SHOULD BE IN A PARENT COMPONENT
      // conditionally assert a hash to determine edit status
      if ( !this.post.contentHash ) {
        this.post.contentHash = crypto.createHash('md5').update(this.post.content).digest("hex")
      }

      // fetch score based on content and number of attachments
      const attachments = this.post.attachments.map((a)=>{ return {_id:a._id}})
      const score = await actions.scorePost(this.dashboard._id, this.post._id, {
        edited: this.post.contentHash === crypto.createHash('md5').update(this.post.content).digest("hex"),
        content: this.post.content,
        attachments: attachments
      })

      Object.keys(score).forEach((key)=>{
        this[key] = score[key]
      })

      // set emotive result
      if ( this.score < 50 ) {
        this.textual = 'Some work to do!'
        this.variant = '🙁'
      } else if ( this.score < 60 ) {
        this.textual = 'Not bad'
        this.variant = '😶'
      } else if ( this.score < 80 ) {
        this.textual = 'Pretttty, prettty good'
        this.variant = '🙂'
      } else {
        this.textual = 'Superstar Post!!'
        this.variant = '😄'
      }

      this.ready = true

      this.$emit('on-score-updated',this.score)

      this.busy = false

    },

    async assess() {

      if ( this.timer ) {
        clearTimeout(this.timer)
        this.timer = false
      }

      this.timer = setTimeout( this.assessImpl, 500 )

    }
  }

}
</script>

