<template>

  <div class="signup-wizard" v-if="ready">
    <component :is="selectedComponent.component" v-if="!user || !user._id" @account-info-selected="setAccountInfo" :message="message" />
    <ProductInfo v-else-if="!subscription || (subscription.status !== 'active' && subscription.status !=='trialing')" :message="message" :user="user" @set-product-info="setProductInfo"/>
    <PublisherInfo v-else="!user.signup.publisher" @publisher-info-selected="setPublisherInfo" :message="message" />    
  </div>

</template>

<script>

'use strict'

import { actions, getters, authenticated } from '@/services/store';
import Logger from '@/services/logger'

import PublisherInfo from './PublisherInfo'
import ProductInfo from './ProductInfo'
import Account1 from './Account1'
import Account2 from './Account2';

const REQUIRE_CC = true

export default {

  name: 'SignupWizard',

  /**
   * this needs rework to move data definition into subcomponents
  **/

  data() {
    return {
      page:'',
      message: '',     
      ready: false,       
      user: undefined,
      plan: undefined,      
      dashboard: undefined,          
      subscription: undefined,
      selectedComponent: null,
    }
  },

  async created() {

    document.body.style.backgroundColor = '#fff';

    if ( authenticated() ) {
      this.user = getters.user()
      this.subscription = getters.subscription() 
    } 

    // Randomly select either Account2 or Account1 component
    this.selectedComponent = this.randomlySelectComponent();

    this.ready = true;

  },

  methods: {

    randomlySelectComponent() {
      const components = [
        { component: Account2, version: 'b' },
        { component: Account1, version: 'a' }
      ];
      const randomIndex =  Math.floor(Math.random() * components.length);
      this.page = components[randomIndex].version
      return components[randomIndex];
    },

    // creates the user
    async setAccountInfo(account) {

      this.message = ''

      const values = {
        referrer: document.referrer,
        signup: {
          version: 'V1',
          state: 'signup',
          account: account,
          signupPageVersion: this.selectedComponent.version          
        }
      }

      try {
        let query = this.$router.currentRoute.query
        if ( REQUIRE_CC ) {
          let resp = await actions.createUser(values,query)
          Logger.registrationStarted(resp.user,this.page)              
          this.user = resp.user  
        } else {
          let resp = await actions.createTrialUser(values,query)
          Logger.noCCRegistration(resp.user,this.page)              
          this.subscription = resp.subscription
          this.dashboard = resp.dashboard        
          this.user = resp.user  
        } 
      } catch( err ) {
        console.error(err)

        if ( process.env['VUE_APP_LATELY_ENV'] !== 'local' && process.env['VUE_APP_RECAPTCHA_KEY'] ) {
          grecaptcha.reset();
        }
        this.message = err.message
      }

    },

    /**
     * 
     * @param {} publisher 
     */
    async setPublisherInfo(publisher) {
      await actions.patchUser( this.user._id, 'signup.publisher', publisher )
      this.user = getters.user()
      this.$router.push(`/${this.dashboard._id}/onboarding`)
    },

    setProductInfo(resp) {
      this.subscription = resp.subscription
      this.dashboard = resp.dashboard        
      this.user = resp.user   
    },        

  },

  components: {
    PublisherInfo,
    Account2,
    Account1,
    ProductInfo
  }

}
</script>

<style lang="scss" >

.signup-wizard {

  margin-top: -30px;
  background-color: #fff;
  font-family: Noto Sans;

  .signup-page {
    height: 100%!important;
    background-color: #fff;

    .title {
      font-size: 42px;
      font-weight: bold;
    }

    .description {
      font-size: 26px;
    }

  }

  // disable form validation feedback
  .was-validated .form-control:invalid, .form-control.is-invalid {
      border-color: #e0b6ba!important;
      background-image:none !important;
  }

  .was-validated .form-control:valid, .form-control.is-valid {
      border-color: #b1dac8!important;
  }

  .invalid-feedback {
    visibility:hidden!important;
  }

}


</style>
